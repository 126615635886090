import './product-teaser.scss';

// import { Slider } from './../../components/slider/slider';
import { A11y, Scrollbar } from 'swiper';

class ProductTeaser {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-productteaser',
            sliderAttr: 'slider'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productTeaser = $element;
        this.$productTeaserSlider = this.$productTeaser.querySelector('[' + this.settings.initAttr + '="slider"]');
        this.$productTeaserSlides = this.$productTeaser.querySelectorAll('[' + this.settings.initAttr + '="slide"]');
        this.productTeaserSlider = null;

        this.resizeHandler = window.resizeHandler;
        this.sliderActive = false;

        if (this.$productTeaserSlides.length > 1) {
            import(
                /* webpackChunkName: "slider/slider.js" */
                './../../components/slider/slider').then(({ Slider }) => {
                this.Slider = Slider;
                this.initialize();
            });
        }
        // this.initialize();
    }

    initialize () {
        console.log('ProductTeaser');
        this.checkSize();
        this.resizeHandler.customFunctions.push(() => {
            this.checkSize();
        });
    }

    checkSize () {
        if (window.innerWidth < 1024 && this.$productTeaserSlides.length > 1) {
            if (this.sliderActive === false) {
                this.initSlider();
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.productTeaserSlider = new this.Slider(this.$productTeaserSlider, {
            modules: [A11y, Scrollbar],
            speed: 500,
            slidesPerView: 'auto',
            scrollbar: true,
            scrollbarAppendToControls: true,
            prevnext: false,
            initAttr: this.settings.initAttr
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.productTeaserSlider !== 'undefined' && this.productTeaserSlider !== null) {
            this.productTeaserSlider.destroy();
        }
    }
}

export { ProductTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productTeaser = $context.querySelectorAll('[data-productteaser="root"]');
        for (let i = 0; i < $$productTeaser.length; i++) {
            const $productTeaser = $$productTeaser[i];

            const productTeaserAPI = new ProductTeaser($productTeaser);
            $productTeaser.API = productTeaserAPI;
        }
    }
});
